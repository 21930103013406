var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-4",attrs:{"min-height":"100","rounded":"lg","outlined":""}},[_c('v-row',_vm._l((_vm.questions),function(question){return _c('v-col',{key:question.position,staticClass:"text-center",attrs:{"md":"4","lg":"3"}},[_c('v-btn',{attrs:{"color":question.position === _vm.questionNumber
            ? 'success'
            : question.isAnswered
            ? 'primary'
            : 'default'},on:{"click":function($event){return _vm.loadQuestion({
            questionId: question.questionId,
            position: question.position
          })}}},[_vm._v(" "+_vm._s(question.position)+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }