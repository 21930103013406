<template>
  <v-sheet class="pa-4" min-height="100" rounded="lg" outlined>
    <v-row>
      <v-col
        v-for="question in questions"
        :key="question.position"
        class="text-center"
        md="4"
        lg="3"
      >
        <v-btn
          :color="
            question.position === questionNumber
              ? 'success'
              : question.isAnswered
              ? 'primary'
              : 'default'
          "
          @click="
            loadQuestion({
              questionId: question.questionId,
              position: question.position
            })
          "
        >
          {{ question.position }}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "QuestionNumberList",
  computed: {
    ...mapGetters("subtest", ["questions", "questionNumber"])
  },
  methods: {
    ...mapActions("subtest", ["loadQuestion"])
  }
};
</script>
