<template>
  <div>
    <v-sheet min-height="200" rounded="lg" outlined>
      <div class="d-flex flex-wrap justify-space-between">
        <v-card-title
          >{{ subtest.testName }} - {{ subtest.subjectName }} •
          {{ this.questionPosition }}</v-card-title
        >
        <v-card-title
          :class="
            `float-right ${
              countdown === $vuetify.lang.t('$vuetify.subtestEnded')
                ? 'red--text'
                : 'black--text'
            }`
          "
          >{{ countdown }}</v-card-title
        >
      </div>
      <v-divider />
      <div class="pa-4">
        <span v-html="question.question"></span>
        <v-divider class="mt-4" />
        <v-radio-group
          v-model="selectedAnswer"
          hide-details
          :disabled="disabledRadioAnswer"
        >
          <v-radio
            v-for="answer in question.answers"
            :key="answer.id"
            :value="answer.id"
            class="ma-2"
          >
            <template v-slot:label>
              <div style="width: 100%">
                <div v-html="answer.answer"></div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="questionNumber > 1"
          @click="
            loadQuestion({
              questionId: findQuestionIdByPosition(questionNumber - 1),
              position: questionNumber - 1
            })
          "
          color="primary"
        >
          <v-icon left>mdi-menu-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.previousQuestion") }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="
            questionNumber === questions.length
              ? confirmEndSubtestDialog()
              : loadQuestion({
                  questionId: findQuestionIdByPosition(questionNumber + 1),
                  position: questionNumber + 1
                })
          "
          :color="questionNumber === questions.length ? 'error' : 'primary'"
          :disabled="
            countdown === $vuetify.lang.t('$vuetify.subtestEnded') &&
              questionNumber === questions.length &&
              subtest.isIrt === 1
          "
        >
          {{
            questionNumber === questions.length
              ? $vuetify.lang.t("$vuetify.endSubtest")
              : $vuetify.lang.t("$vuetify.nextQuestion")
          }}
          <v-icon v-if="questionNumber !== questions.length" right
            >mdi-menu-right</v-icon
          >
        </v-btn>
      </v-card-actions>
    </v-sheet>

    <modal
      v-model="endSubtestDialog"
      :loading="endSubtestLoading"
      :handler="endSubtest"
      :title="$vuetify.lang.t('$vuetify.modal.endSubtest.title')"
      :description="
        countdown === $vuetify.lang.t('$vuetify.subtestEnded')
          ? $vuetify.lang.t('$vuetify.modal.endSubtest.confirmCommand')
          : `${$vuetify.lang.t(
              '$vuetify.modal.endSubtest.timeLeft',
              countdown
            )}${
              unansweredQuestionCount > 0
                ? unansweredQuestionCount > 1
                  ? $vuetify.lang.t(
                      '$vuetify.modal.endSubtest.unansweredQuestionPlural',
                      unansweredQuestionCount
                    )
                  : $vuetify.lang.t(
                      '$vuetify.modal.endSubtest.unansweredQuestion'
                    )
                : ''
            }`
      "
      :text-button="$vuetify.lang.t('$vuetify.modal.endSubtest.textButton')"
      max-width="290"
    />

    <ongoing-test-modal
      v-if="ongoingTestDialog"
      v-model="ongoingTestDialog"
      :ongoing-subtest-loading="ongoingSubtestLoading"
      :ongoing-test="test"
      :subtests="subtests"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal";
import OngoingTestModal from "@/components/modal/OngoingTestModal";
import SubtestService from "@/services/subtest";
import TestService from "@/services/test";

export default {
  name: "QuestionBox",
  data() {
    return {
      endSubtestDialog: false,
      endSubtestLoading: false,
      ongoingTestDialog: false,
      ongoingSubtestLoading: false,
      test: {
        name: "",
        testEnd: new Date()
      },
      subtests: [],
      runningTestId: ""
    };
  },
  components: {
    Modal,
    OngoingTestModal
  },
  computed: {
    ...mapGetters("subtest", [
      "countdown",
      "disabledRadioAnswer",
      "findQuestionIdByPosition",
      "id",
      "originalSelectedAnswer",
      "question",
      "questionNumber",
      "questions",
      "subtest",
      "unansweredQuestionCount"
    ]),
    questionPosition() {
      return `${this.questionNumber}/${this.questions.length}`;
    },
    selectedAnswer: {
      get() {
        return this.$store.getters["subtest/selectedAnswer"];
      },
      set(selectedAnswer) {
        this.$store.commit("subtest/setSelectedAnswer", selectedAnswer);
      }
    }
  },
  async created() {
    await Promise.allSettled([
      this.findTestInformation(this.id),
      this.findRunningSubtestById(this.id)
    ]);
  },
  methods: {
    ...mapActions("subtest", [
      "loadQuestion",
      "answerQuestion",
      "setCountdown"
    ]),
    async confirmEndSubtestDialog() {
      if (
        this.selectedAnswer !== undefined &&
        this.selectedAnswer !== this.originalSelectedAnswer &&
        this.countdown !== this.$vuetify.lang.t("$vuetify.subtestEnded")
      ) {
        await this.answerQuestion();
      }
      this.endSubtestDialog = true;
    },
    async endSubtest() {
      this.endSubtestLoading = true;
      try {
        await SubtestService.endTest(this.id);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.ongoingTestDialog = true;
        this.ongoingSubtestLoading = true;
        this.subtests = await TestService.runningSubtests(this.runningTestId);
        this.ongoingSubtestLoading = false;
        this.endSubtestDialog = false;
        this.setCountdown(this.$vuetify.lang.t("$vuetify.subtestEnded"));
      } catch (error) {
        this.endSubtestLoading = false;
      }
    },
    async findTestInformation(runningSubtestId) {
      this.test = await SubtestService.findTestInformation(runningSubtestId);
    },
    async findRunningSubtestById(runningSubtestId) {
      const runningSubtest = await SubtestService.findRunningSubtestById(
        runningSubtestId
      );
      this.runningTestId = runningSubtest.runningTestId;
    }
  }
};
</script>

<style>
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.87);
}
</style>
