<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" order-md="last">
        <question-number-list />
      </v-col>
      <v-col cols="12" md="8" order-md="first">
        <question-box />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="css" scoped>
@import "~quill/dist/quill.snow.css";
.pointer-cursor:hover {
  cursor: pointer;
}
</style>

<script>
import katex from "katex";
import { mapActions, mapGetters } from "vuex";
import QuestionBox from "@/components/QuestionBox.vue";
import QuestionNumberList from "@/components/QuestionNumberList";
import "katex/dist/katex.min.css";

export default {
  name: "Subtest.Subtest",
  data() {
    return {
      countdown: "",
      runningTestId: "",
      startSubtestConfirmationDialog: false,
      endTestDialog: false,
      endTestLoading: false,
      startSubtestLoading: false
    };
  },
  components: {
    QuestionBox,
    QuestionNumberList
  },
  computed: {
    ...mapGetters("subtest", [
      "questions",
      "questionNumber",
      "findQuestionIdByPosition",
      "timer"
    ]),
    dataId() {
      return this.$route.params.id;
    }
  },
  mounted() {
    window.katex = katex;
  },
  destroyed() {
    clearInterval(this.timer);
  },
  async created() {
    this.resetState();
    this.setNavbarTitle("Subtest");
    this.setId(this.dataId);
    await this.getTime();
    await Promise.allSettled([
      this.findRunningSubtest(),
      this.findRunningSubtestQuestions().then(() =>
        this.loadQuestion({
          questionId: this.findQuestionIdByPosition(this.questionNumber),
          position: this.questionNumber
        })
      )
    ]);
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    ...mapActions("subtest", [
      "getTime",
      "findRunningSubtest",
      "findRunningSubtestQuestions",
      "loadQuestion",
      "resetState",
      "setId"
    ])
  }
};
</script>

<style>
.v-application p {
  margin-bottom: 0px !important;
}
.v-application .accent {
  background-color: #82b1ff00 !important;
  border-color: #82b1ff00 !important;
}
</style>
