var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{attrs:{"min-height":"200","rounded":"lg","outlined":""}},[_c('div',{staticClass:"d-flex flex-wrap justify-space-between"},[_c('v-card-title',[_vm._v(_vm._s(_vm.subtest.testName)+" - "+_vm._s(_vm.subtest.subjectName)+" • "+_vm._s(this.questionPosition))]),_c('v-card-title',{class:("float-right " + (_vm.countdown === _vm.$vuetify.lang.t('$vuetify.subtestEnded')
              ? 'red--text'
              : 'black--text'))},[_vm._v(_vm._s(_vm.countdown))])],1),_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.question.question)}}),_c('v-divider',{staticClass:"mt-4"}),_c('v-radio-group',{attrs:{"hide-details":"","disabled":_vm.disabledRadioAnswer},model:{value:(_vm.selectedAnswer),callback:function ($$v) {_vm.selectedAnswer=$$v},expression:"selectedAnswer"}},_vm._l((_vm.question.answers),function(answer){return _c('v-radio',{key:answer.id,staticClass:"ma-2",attrs:{"value":answer.id},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('div',{domProps:{"innerHTML":_vm._s(answer.answer)}})])]},proxy:true}],null,true)})}),1)],1),_c('v-divider'),_c('v-card-actions',[(_vm.questionNumber > 1)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.loadQuestion({
            questionId: _vm.findQuestionIdByPosition(_vm.questionNumber - 1),
            position: _vm.questionNumber - 1
          })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-menu-left")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.previousQuestion"))+" ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.questionNumber === _vm.questions.length ? 'error' : 'primary',"disabled":_vm.countdown === _vm.$vuetify.lang.t('$vuetify.subtestEnded') &&
            _vm.questionNumber === _vm.questions.length &&
            _vm.subtest.isIrt === 1},on:{"click":function($event){_vm.questionNumber === _vm.questions.length
            ? _vm.confirmEndSubtestDialog()
            : _vm.loadQuestion({
                questionId: _vm.findQuestionIdByPosition(_vm.questionNumber + 1),
                position: _vm.questionNumber + 1
              })}}},[_vm._v(" "+_vm._s(_vm.questionNumber === _vm.questions.length ? _vm.$vuetify.lang.t("$vuetify.endSubtest") : _vm.$vuetify.lang.t("$vuetify.nextQuestion"))+" "),(_vm.questionNumber !== _vm.questions.length)?_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-right")]):_vm._e()],1)],1)],1),_c('modal',{attrs:{"loading":_vm.endSubtestLoading,"handler":_vm.endSubtest,"title":_vm.$vuetify.lang.t('$vuetify.modal.endSubtest.title'),"description":_vm.countdown === _vm.$vuetify.lang.t('$vuetify.subtestEnded')
        ? _vm.$vuetify.lang.t('$vuetify.modal.endSubtest.confirmCommand')
        : ("" + (_vm.$vuetify.lang.t(
            '$vuetify.modal.endSubtest.timeLeft',
            _vm.countdown
          )) + (_vm.unansweredQuestionCount > 0
              ? _vm.unansweredQuestionCount > 1
                ? _vm.$vuetify.lang.t(
                    '$vuetify.modal.endSubtest.unansweredQuestionPlural',
                    _vm.unansweredQuestionCount
                  )
                : _vm.$vuetify.lang.t(
                    '$vuetify.modal.endSubtest.unansweredQuestion'
                  )
              : '')),"text-button":_vm.$vuetify.lang.t('$vuetify.modal.endSubtest.textButton'),"max-width":"290"},model:{value:(_vm.endSubtestDialog),callback:function ($$v) {_vm.endSubtestDialog=$$v},expression:"endSubtestDialog"}}),(_vm.ongoingTestDialog)?_c('ongoing-test-modal',{attrs:{"ongoing-subtest-loading":_vm.ongoingSubtestLoading,"ongoing-test":_vm.test,"subtests":_vm.subtests},model:{value:(_vm.ongoingTestDialog),callback:function ($$v) {_vm.ongoingTestDialog=$$v},expression:"ongoingTestDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }